import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';
import constants from '../../../contants';
import {  useSelector } from 'react-redux';
import './client.css'
const { clients } = constants;

function Client() {
    const { t } = useTranslation();
    let nightMode = useSelector(state => state.NightMode);

    var settings = {
        className: "center",
        infinite: true,
        autoplay: true,
        arrows: false,
        centerMode: true,
        centerPadding: "10px",
        speed: 1200,
        responsive: [
            {
                breakpoint: 5000, // Adjust the breakpoint as needed
                settings: {
                  slidesToShow: 5,
                },
            },
            {
                breakpoint: 1125, // Adjust the breakpoint as needed
                settings: {
                  slidesToShow: 4,
                },
            },
            {
              breakpoint: 880, // Adjust the breakpoint as needed
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 500, // Adjust the breakpoint as needed
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 350, // Adjust the breakpoint as needed
              settings: {
                slidesToShow: 1.95,
              },
            },
        ],
    };
  return (
    <div className={`my-5 pt-3 pb-5 client-section ${nightMode?"bg-dark night-text-color":"bg-light"}`}>
        <Row className='justify-content-center my-5' >
            <Col sm="auto">
                <h2 className='clients-heading'>{t("ourclients")}</h2>
            </Col>
        </Row>
        <Row className='m-2'>
            <Col>
                <Slider {...settings}>
                    {clients.map((client, index) => (
                        <>
                            <div className='d-flex justify-content-center overflow-hidden' key={index}>
                                <img className='clientLogo' src={client.logo} alt={client.name} />
                            </div>
                            <p className='clientName d-flex justify-content-center mt-3'>{client.name}</p>
                        </>
                    ))}
                </Slider>
            </Col>
        </Row>
    </div>
  )
}

export default Client