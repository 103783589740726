// SuccessModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './successModel.css'; // Import your custom styles

const SuccessModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body  className="modal-body">
        <Modal.Title className="modal-title mb-4">
            <i className="fas fa-envelope-open-text pr-5"></i> Email Sent
        </Modal.Title>
        
        <p className="modal-text mb-5">We will get back to you soon. Thank you!</p>
      </Modal.Body>
        <button className="close-icon" onClick={handleClose}>
          <i className="fas fa-times"></i>
        </button>
    </Modal>
  );
};

export default SuccessModal;
