import React from 'react'
import { motion } from "framer-motion";
import {Row } from 'react-bootstrap'
import html5 from "../../../assests/html5.png"
import tailwind from "../../../assests/tailwind.png"
import javascript from "../../../assests/javascript.png"
import react from "../../../assests/react.png"
import Nodejs from "../../../assests/nodejs.png"
import typescript from "../../../assests/typescript.png"
import python from "../../../assests/python.png"
import java from "../../../assests/java.png"
import postgresql from "../../../assests/postgresql.png"
import mongodb from "../../../assests/mongodb.png"
import mysql from "../../../assests/mysql.png"
import aws from "../../../assests/aws.png"
import firebase from "../../../assests/firebase.png"
import heroku from "../../../assests/heroku.png"
import DynamoDB from "../../../assests/DynamoDB.png"
import { useTranslation } from 'react-i18next';
import "./technology.css"
const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
};
const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
};
const technologies = [
    {
        usage:"Frontend",
        technologiesImages:[
            { imageUrl: html5, altText: "html5 Icon" },
            { imageUrl: javascript, altText: "javascript Icon" },
            { imageUrl: tailwind, altText: "tailwind Icon" },
            { imageUrl: react, altText: "react Icon" },
        ]
    },
    {
        usage:"Backend",
        technologiesImages:[
            { imageUrl: typescript, altText: "Typescript Icon" },
            { imageUrl: Nodejs, altText: "NodeJs Icon" },
            { imageUrl: python, altText: "Python Icon" },
            { imageUrl: java, altText: "Java Icon" },
        ]
    },
    {
        usage:"Databases",
        technologiesImages:[
            { imageUrl: postgresql, altText: "postgresql Icon" },
            { imageUrl: mongodb, altText: "mongodb Icon" },
            { imageUrl: mysql, altText: "mysql Icon" },
            { imageUrl: DynamoDB, altText: "DynamoDB Icon" },
        ]
    },
    {
        usage:"DevOps",
        technologiesImages:[
            { imageUrl: aws, altText: "aws Icon" },
            { imageUrl: firebase, altText: "firebase Icon" },
            { imageUrl: "https://www.digitalocean.com/_next/static/media/do-logo.d3eb8550.svg", altText: "digitalocean Icon" },     
            { imageUrl: heroku, altText: "heroku Icon" },
        ]
    },
]
function Technologies() {
  const { t } = useTranslation();
  return (
        <div className='technology-section text-light'>
            <Row>
                <div className='technology-container'>
                    <h2 className='technology-heading'>{t("technologies")}</h2>
                    <p className='technology-description'>
                        {t("technologiessubdescription")}
                    </p>
                </div>
                <div>
                    <div className='technologies-subcontainers'>
                        {technologies.map((technology)=>(
                            <div>
                                <div className='technology-images-container'>
                                    <motion.ul
                                        className="images-container"
                                        variants={container}
                                        initial="hidden"
                                        animate="visible"
                                    >
                                        {technology.technologiesImages.map((image, index) => (
                                            <motion.li key={index} className="item" variants={item}>
                                                <img width={60} src={image.imageUrl} alt={image.altText} />
                                            </motion.li>
                                        ))}
                                    </motion.ul>
                                </div>
                                <h3 style={{display:'flex',justifyContent:'center'}}>{technology.usage}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </Row>
        </div>
  )
}

export default Technologies