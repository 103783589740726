import staytoenjoylogo from './assests/staytoenjoylogo.svg'
import makingcents from "./assests/makingcents.png"
import simplealliance from "./assests/simplealliance.png"
import simplealliance1 from "./assests/simplealliance1.png"
import engineering from "./assests/2050engineering.png"
import grepcosystems from "./assests/grepcosystems.png"
import grepcosystems1 from "./assests/grepcosystems1.png"
import Nextjs from "./assests/nextjs.png"
import ReactJs from "./assests/react.png"
import NodeJs from "./assests/nodejs.png"
import MongoDB from "./assests/mongodb.png"
import TypeScript from "./assests/typescript.png"
import Redis from "./assests/redis.png"
import PostgreSQL from "./assests/postgresql.png"
import Firebase from "./assests/firebase.png"
import PasswordImage from "./assests/Password.png"
import Python from "./assests/python.png"
import Inkspire from "./assests/Inkspire.png"
import Figma from "./assests/figma.png"
const serveromProjectCover= "https://res.cloudinary.com/dncfmkek9/image/upload/v1595861551/omer%20mustafa%20web%20developer%20portfolio/serverom-big.png";
const InventoryProjectCover = "https://res.cloudinary.com/dlbm3b0oh/image/upload/v1689262949/Inventory_gdtxmk.png";
const datavisualiaztionProjectCover ="https://mubasharhussain.netlify.app/static/media/rtdv.c4df63695a52400f4528.webp";
const staytoenjoyProjectcover ='https://res.cloudinary.com/dlbm3b0oh/image/upload/v1689262523/staytoenjoy_ntxogo.png'
const shareeftex = "https://shareeftex.com/wp-content/themes/quasartheme/images/demo/quasar-logo.png"
const GoogleAnalytics = "https://www.gstatic.com/analytics-suite/header/suite/v2/ic_analytics.svg"
const MaterialUI = "https://mui.com/static/logo.png"
const Github = "https://1000logos.net/wp-content/uploads/2021/05/GitHub-logo.png"
const DigitalOcean = "https://www.digitalocean.com/_next/static/media/do-logo.d3eb8550.svg"
const Cloudinary = "https://res-s.cloudinary.com/prod/image/upload/d_console:cld_new_default_cloud_logo_regular_padding.svg/w_32,h_32,c_fill,dpr_2.0/console/customer-logos/78c1f24f2063f8cd0bc95b9b75a9cb15"
const Canva ="https://static.canva.com/web/images/12487a1e0770d29351bd4ce4f87ec8fe.svg"
const EJS = "https://ejspr.com/app/uploads/2021/03/EJS-Monogram_Grass-Green_High-Res.png"
const Shopify = "https://play-lh.googleusercontent.com/6zZznDl5GHwm_BdOTTXEpB28q1yUxjdjf1Me6qTzHw0-SH4MEjdJok0sC5Daru5suA=w600-h300-pc0xffffff-pd"

const productionAPI = "https://api.bringitgo.tech";

const clients = [
    {
        name:"2550 Engineering",
        logo:engineering,
    },
    {
        name:"Grepco Systems",
        logo:grepcosystems1,
    },
    {
        name:"Inventory",
        logo:"https://inventory-a761c.web.app/favicon.png"
    },
    {
        name:"MakingCents",
        logo:makingcents
    },
    {
        name:"Shareeftex",
        logo:shareeftex
    },
    {
        name:"Simple Alliance",
        logo:simplealliance1
    },
    {
        name:"StaytoEnjoy",
        logo:staytoenjoylogo
    }
]

const projectsCovers = {
    datavisualiaztion:{
        Image:datavisualiaztionProjectCover
    },
    dressbylinda:{
        Image:require("./assests/Linda-project-cover.png")
    },
    exposurestudio:{
        Image:require("./assests/Exposure-studio-project-cover.png")
    },
    inkspire:{
        Image:Inkspire
    },
    inventory:{
        Image:InventoryProjectCover
    },
    pinggo:{
        Image:require("./assests/Pinggo-project-cover.png")
    },
    password:{
        Image:PasswordImage
    },
    serverom:{
        Image:serveromProjectCover
    },
    shareeftex:{
        Image:require("./assests/ShareefTex.png")
    },
    staytoenjoy:{
        Image:staytoenjoyProjectcover
    },
    
}

const logoImages = [
    makingcents,
    simplealliance,
    engineering,
    grepcosystems
]

const techStack=[
    {
        name:"NextJS",
        src:Nextjs,
    },
    {
        src:ReactJs,
        name:"React"
    },
    {
        src:MaterialUI,
        name:"Material UI"
    },
    {
        src:NodeJs,
        name:"Nodejs"
    },
    {
        src:MongoDB,
        name:"MongoDB"
    },
    {
        src:TypeScript,
        name:"TypeScript"
    },
    {
        src:Redis,
        name:"Redis"
    },
    {
        src:Github,
        name:"GitHub"
    },
    {
        src:DigitalOcean,
        name:"Digital Occean"
    },
    {
        src:PostgreSQL,
        name:"PostgreSQL"
    },
    {
        src:Firebase,
        name:"Firebase"
    },
    {
        src: Figma,
        name:"Figma"
    },
    {
        src:GoogleAnalytics,
        name:"Google Analytics"
    },
    {
        src:Cloudinary,
        name:"Cloudinary"
    },
    {
        src:Canva,
        name:"Canva"
    },
    {
        src:EJS,
        name:"EJS"
    },
    {
        src:Python,
        name:"Python"
    },
    {
        src:Shopify,
        name:"Shopify"
    },
]
// Keep in sorted order
const projects= [
    {
        Name:"Data Visualization",
        id:"data-visualization",
        Company:"2550 Engineering",
        Challenge:`Building real-time data visualization on a web portal with visually compelling representations,
        including dynamic and immersive 3D object rendering based on the data.`,
        Location:"Sweden",
        Category:["Web","Data Visualization",],
        Link:"/project/data-visualization",
        StartDate:"Apirl/2022",
        EndDate:"March/2022",
        TechStack:[
            ReactJs,
            NodeJs,
            PostgreSQL,
            Github,
        ],
        Image:datavisualiaztionProjectCover,
        GIF:"http://cdn.bringitgo.tech/dataVisualiztion.gif",
        video:"P38EPSikYj4",
        Description:`Our expert team gathers sensor data for precise business insights. We craft a robust web portal featuring captivating 2D and 3D data visualization, breathing life into your information. Throughout development, we offer valuable insights, promptly incorporating enhancements. Trust our dedicated team for on-time project completion, ensuring your satisfaction with outstanding results.`
    },
    {
        id:"dresses-by-linda-and-agnes",
        Name:"Dresses by Linda And Agnes",
        Company:"Dresses by Linda And Agnes",
        Challenge:`Dresses by Linda And Agnes has a shopify website and are the leading e-commerce player in wedding dresses.       
        They were facing a critical frontend issues, which were devaluing their sale and business.`,
        Location:"USA",
        Category:["Web","Shopify",],
        Link:"/project/dresses-by-linda-and-agnes",
        StartDate:"Oct/2020",
        EndDate:"March/2021",
        TechStack:[
            Shopify,
        ],
        Image:projectsCovers.dressbylinda.Image,
        GIF:"http://cdn.bringitgo.tech/Linda.gif",
        src:"4pIXrx3bFoI",
        // Image:"https://bornbeehosting.com:2083/cpsess0602485254/viewer/home%2fbringitg%2fbringit-go-assets/Linda.gif",
        Description:`We fixed the most of the website issues and made some custom changes to the theme as per client's requirements.`
    },
    {
        id:"exposure-studio",
        Name:"Exposure Studio",
        Company:"Exposure Studio",
        Challenge:`Exposure studio has a shopify website and are the leading e-commerce player in custom design printing.       
        They were facing a critical frontend issues, which were devaluing their sale and business.`,
        Location:"UK",
        Category:["Web","Shopify",],
        Link:"/project/exposure-studio",
        StartDate:"Oct/2020",
        EndDate:"March/2021",
        TechStack:[
            Figma,
            Shopify,
        ],
        Image:projectsCovers.exposurestudio.Image,
        GIF:null,
        Description:`We designed the website to have better user experience and made some custom changes to the theme as per client's requirements.`
    },
    {
        id:"inkspire",
        Name:"Inkspire",
        Company:"Making Cents",
        Challenge:`A blogging website to provide the reward in crypto-currency both to the writers and readers.
        Want to accomodate vite blockchain and write smart contract based on the idea. Website design was suppose to build
        according to clients inspiration.`,
        Location:"United Kingdom",
        Category:["Web","Custom Solution","Designing", "Cloud Hosting", "Blockchain"],
        Link:"/project/inkspire",
        StartDate:"May/2023",
        EndDate:"Present",
        TechStack:[
            Figma,
            ReactJs,
            MaterialUI,
            NodeJs,
            PostgreSQL,
            Github,
            DigitalOcean,
        ],
        Image:Inkspire,
        GIF:"http://cdn.bringitgo.tech/inkspire.gif",
        video:"GOLO6DF3_xo",
        Description:`To address the website idea bringitgo work on designing the website for different devices from the client's    
        requirements Client was the purpose of the development of a better tech stack solution to speed the development and cut     
        the cost of it. Before launching the website client was given a website for the trial. The product was aligned and fixed    
        according to the client's satisfaction.`
    },
    {
        id:"inventory-store",
        Name:"Inventory",
        Company:"Inventory",
        Challenge:`Unleash your creativity and expertise to revolutionize the world of E-Commerce!
        We were challenged to construct a cutting-edge serverless platform, encompassing everything from visionary design to a fully functional prototype product.
        This extraordinary project demands your exceptional skills to deliver a seamless user experience within a remarkably tight timeframe`,
        Location:"Pakistan",
        Link:"/project/inventory-store",
        Category:["Web","Custom Solution","Designing", "Cloud Hosting"],
        StartDate:"Oct/2020",
        EndDate:"March/2021",
        TechStack:[
            ReactJs,
            Firebase,
            GoogleAnalytics,
            Github,
            Cloudinary,
        ],
        Image:InventoryProjectCover,
        GIF:null,
        video:null,
        Description:`Experience the power of our comprehensive E-Commerce solution designed exclusively for a diverse range of marketplaces.
        From initial design to seamless implementation, we offer an all-in-one platform that empowers businesses to effortlessly track their audience, supercharge sales, and maximize results with Google Ads integration.
        With our cutting-edge serverless solution, you can accelerate your operations and unlock the full potential of your business in no time.`
    },
    {
        id:"serverom",
        Name:"Serverom",
        Company:"Serverom",
        Challenge:`With Serverom, developers can deploy a server, setup and upload their web applications, set up their emails,     
        issue 100% free SSL certificates to secure applications & boost their search rankings, and also install one-click custom    
        applications such as WordPress, etc all in a blink.`,
        Location:"Pakistan",
        Link:"/project/serverom",
        Category:["Web","Custom Solution","Designing", "Cloud Hosting"],
        StartDate:"Sep/2019",
        EndDate:"Jun/2020",
        TechStack:[
            NodeJs,
            PostgreSQL,
            Github,
            EJS,
        ],
        Image:serveromProjectCover,
        GIF:null,
        video:null,
        Description:`Serverom is a managed cloud hosting platform that automates the cloud server deployment & it’s management using a click of a button.
        With Serverom, developers can deploy a server, setup and upload their web applications, set up their emails,
        issue 100% free SSL certificates to secure applications & boost their search rankings, and also install one-click custom    
        applications such as WordPress, etc all in a blink. Besides building & achieving our primary motive of a one-stop solution  
        for developers, we at the same time accomplished groundbreaking results for server stack optimization, leveraging its       
        resources to the point that it blew our minds. Our builtin stack performed 4x times better than NGINX (most widely
        used webserver) for all sorts of applications with 3x lesser resources under traffic stress/load testing
        (http://research.serverom.com).`
    },
    {
        id:"shareef-tex",
        Name:"ShareefTex",
        Company:"ShareefTex",
        Challenge:`ShareedTex is a multinational company working manufacturing goods in for safety.
        Previously they have been using hard printed catalogue and every year they had new products to be list in the catalogue.    
        We justed the solution of soft copy and designed they catalogue from scratch.`,
        Location:"Pakistan",
        Link:"/project/shareef-tex",
        Category:["Designing",],
        StartDate:"Oct/2020",
        EndDate:"March/2021",
        TechStack:[
            Canva,
            Figma
        ],
        Image:projectsCovers.shareeftex.Image,
        GIF:null,
        video:null,
        Description:`Experience the power of our comprehensive E-Commerce solution designed exclusively for a diverse range of marketplaces.
        From initial design to seamless implementation, we offer an all-in-one platform that empowers businesses to effortlessly track their audience, supercharge sales, and maximize results with Google Ads integration.
        With our cutting-edge serverless solution, you can accelerate your operations and unlock the full potential of your business in no time.`
    },
    {
        id:"staytoenjoy",
        Name:"StayToEnjoy",
        Company:"StayToEnjoy",
        Challenge:`We embarked on the daunting task of creating a unique web application that combined the power
        of socialization and e-commerce. It was no ordinary project; it demanded the integration of diverse
        e-commerce solutions and social media functionalities. This endeavor posed immense challenges in terms
        of development complexity, limited budgetary resources, and the crucial need for timely delivery.
        The path we treaded was rugged, pushing our capabilities to the limit.`,
        Location:"Germany",
        Link:"/project/staytoenjoy",
        Category:["Web","Custom Solution","Designing", "Cloud Hosting"],
        StartDate:"August/2022",
        EndDate:"Present",
        TechStack:[
            Nextjs,
            ReactJs,
            MaterialUI,
            NodeJs,
            MongoDB,
            TypeScript,
            Redis,
            Github,
            DigitalOcean
        ],
        Image:staytoenjoyProjectcover,
        GIF:null,
        video:null,
        Description:`To overcome the challenges of creating a unique web application that seamlessly integrated
        socialization and e-commerce, we devised a comprehensive solution that addressed the complex development
        requirements, limited budgetary resources, and the critical need for timely delivery. Firstly, we
        conducted an in-depth analysis of various e-commerce solutions and social media functionalities to
        identify the most suitable technologies and platforms. This meticulous approach allowed us to leverage
        the strengths of existing tools and frameworks, saving both development time and budget. To optimize
        development complexity, we adopted an agile methodology that emphasized frequent communication and
        collaboration. By breaking down the project into smaller, manageable tasks, we ensured a smooth
        development process while accommodating changes and updates throughout the project lifecycle. Considering
        the limited budgetary resources, we carefully prioritized features and functionalities to maximize value
        within the available financial constraints. This approach allowed us to deliver a functional and
        user-friendly web application that met the project's core objectives without compromising on quality.
        To ensure timely delivery, we established a strict project management framework with well-defined
        milestones, regular progress tracking, and efficient resource allocation. This systematic approach
        enabled us to meet deadlines and keep the project on track, even when faced with unforeseen challenges.
        Throughout the development journey, our team's unwavering dedication, expertise, and resilience
        played a vital role. We embraced the rugged path and pushed our capabilities to the limit,
        overcoming obstacles and delivering a web application that seamlessly merged socialization and
        e-commerce, providing a unique and engaging user experience. In conclusion, our solution combined
        meticulous planning, agile development methodologies, strategic prioritization, and effective
        project management to overcome the daunting challenges of integrating diverse e-commerce solutions
        and social media functionalities. The end result is a web application that not only meets the project's
        objectives but also exceeds expectations in terms of functionality, user experience, and
        timely delivery.`
    },
    {
        id:"passwordscript",
        Name:"Password Manager",
        Company:"Freelancer",
        Location:"Pakistan",
        Link:"/project/passwordscript",
        Category:["Scripts"],
        StartDate:"June/2019",
        EndDate:"July/2019",
        TechStack:[
            Python
        ],
        Image:PasswordImage,
        GIF:null,
        video:null,
        Challenge:`The client sought a secure Windows password management solution for easy retrieval and storage of account passwords. Our solution enables them to quickly fetch passwords by entering the account name and streamline the copying process to the clipboard via the Windows run search function, enhancing both user experience and security.`,
        Description:`Within a month, we successfully developed a password management solution for the client's Windows laptop. The system allows seamless retrieval of stored passwords by entering the account name. Furthermore, the client can swiftly copy passwords to the clipboard using the Windows run search function, streamlining the user experience and meeting their expectations effectively.`
    },
]

export default {
    productionAPI,
    clients,
    logoImages,
    techStack,
    projectsCovers,
    projects
}