import React,{ useState } from 'react';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from 'swiper';
import Particles from '../../component/layout/particles/index';
import Footer from '../../component/common/footer';
import Constants from "../../contants";
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import {  useSelector } from 'react-redux';
import './portfolio.css';
let projects = Constants.projects;
// Initialize Swiper core modules
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const Portfolio = () => {
  const { t } = useTranslation();
  let nightMode = useSelector(state => state.NightMode);
  const [selectedCategory, setSelectedCategory] = useState('All'); // Initialize state

  // Filter projects based on the selected category
  const filteredProjects = selectedCategory === 'All'
    ? projects
    : projects.filter(project => (
      Array.isArray(project.Category)
        ? project.Category.includes(selectedCategory)
        : project.Category === selectedCategory
  ));
  const renderArray = () => {
    const elements = [];

    for (let i = 0; i < filteredProjects.length; i+=2) {
      elements.push(
        <Row key={i / 2}>
          <Col className='d-flex justify-content-center p-3 m-3'>
            <div className='project-container-background'></div>
            <a href={filteredProjects[i].Link}>
              <img className='portfolio-project-image' src={filteredProjects[i].Image}/>
              <h3 className='portfolio-project-heading'>{filteredProjects[i].Name}</h3>
            </a>
          </Col>
          {i + 1 < filteredProjects.length && (
            <Col className='d-flex justify-content-center p-3 m-3'>
              <div className='project-container-background'></div>
              <a href={filteredProjects[i+1].Link}>
                <img className='portfolio-project-image' src={filteredProjects[i+1].Image}/>
                <h3 className='portfolio-project-heading'>{filteredProjects[i+1].Name}</h3>
              </a>
            </Col>
          )}
        </Row>
      );
    }

    return elements;
  };
  return (
    <div className={nightMode?"night-theme":"day-theme"}>
      <div className="projects" id="portfolio">
        <Row>
          <Col>
              <h1 className="portfolio-heading">{t("portfolio")}</h1>
          </Col>
        </Row>
        <Row>
            <Col>
              <p className='portfolio-description'>{t("portfolioDescription")}</p>
            </Col>
        </Row>
        <div className='projects-container-background'>
          <Row>
            <h2 className='project-heading mt-5'>{t("casestudies")}</h2>
          </Row>
          <Row>
            <Nav variant="underline" defaultActiveKey="/" className="projects-types">
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'All' ? 'active' : ''}`} onClick={() => setSelectedCategory('All')}>
                {t("All")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Blockchain' ? 'active' : ''}`} onClick={() => setSelectedCategory('Blockchain')}>
                {t("blockchain")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Custom Solution' ? 'active' : ''}`} onClick={() => setSelectedCategory('Custom Solution')}>
                {t("customSolution")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Data Visualization' ? 'active' : ''}`} onClick={() => setSelectedCategory('Data Visualization')}>
                {t("dataVisualization")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Designing' ? 'active' : ''}`} onClick={() => setSelectedCategory('Designing')}>
                {t("designing")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Cloud Hosting' ? 'active' : ''}`} onClick={() => setSelectedCategory('Cloud Hosting')}>
                {t("cloudHosting")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Mobile' ? 'active' : ''}`} onClick={() => setSelectedCategory('Mobile')}>
                {t("mobile")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Web' ? 'active' : ''}`} onClick={() => setSelectedCategory('Web')}>
                {t("web")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Shopify' ? 'active' : ''}`} onClick={() => setSelectedCategory('Shopify')}>
                {t("shopify")}
              </Nav.Link>
              <Nav.Link className={`project-type border px-3 d-flex text-white align-items-center ${selectedCategory === 'Scripts' ? 'active' : ''}`} onClick={() => setSelectedCategory('Scripts')}>
                {t("scripts")}
              </Nav.Link>
            </Nav>
          </Row>
          {renderArray()}
        </div>

        {/* <Row className='vertical-swiper'>
          <Swiper
            direction="vertical"
            effect="coverflow"
            grabCursor
            centeredSlides
            loop
            pagination={{ clickable: true }}
            classNames={{
              wrapper: 'vertical-swiper-wrapper',
              slide: 'vertical-swiper-slide',
              pagination:'vertical-swiper-pagination',
              // Add more class names as needed
            }}
            className="vertical-swiper-container"
          >
            {projectData.map((project) => (
              <SwiperSlide key={project.id}>
                <div className="vertical-swiper-slide-content">
                  <a href={`/project/${project.id}`}>
                    <img className='vertical-img' src={project.image} alt={`Project ${project.id}`} />
                  </a>
                  <div className="project-info">
                    <h3>{project.heading}</h3>
                    <p>{project.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row> */}
      </div>
      <Footer/>
    </div>
  );
};

export default Portfolio;