import React, { useState } from "react";
import Header from './component/common/header'
import Home from './pages/home/index'
import ProjectDisplay from './pages/projectDisplay/index'
import NoPageFound from './pages/noPageFound/index'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutUS from "./pages/aboutUs";
import Portfolio from "./pages/portfolio";
import Pricing from "./pages/price";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>}></Route>
          <Route exact path='/project/:id' element={<ProjectDisplay/>}></Route>
          <Route exact path='/about-us' element={<AboutUS/>}></Route>
          <Route exact path='/portfolio' element={<Portfolio/>}></Route>
          <Route exact path='/pricing' element={<Pricing/>}></Route>
          <Route path='*' element={<NoPageFound/>}></Route>
        </Routes>
    </Router>
  </>
  );
}

export default App;