import React from 'react'
import Header from '../../component/common/header'
import ProjectBody from '../../component/layout/projectBody'
import Contact from '../../component/layout/contact-form'

function ProjectDisplay() {
  return (
      <>
          <Header />
          <ProjectBody />
          <Contact/>
      </>
  )
}

export default ProjectDisplay