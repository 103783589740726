// redux/reducers.js
import { combineReducers } from 'redux';
import { TOGGLE_DAY_NIGHT_MODE } from './action';

// Fetch initial state from localStorage or use a default value
const initialState = JSON.parse(localStorage.getItem('mode')) || {
  NightMode: false,
};

const NightModeReducer = (state = initialState.NightMode, action) => {
  switch (action.type) {
    case TOGGLE_DAY_NIGHT_MODE:
      const newState = !state;

      // Save the updated state in localStorage
      localStorage.setItem('mode', JSON.stringify({ NightMode: newState }));

      return newState;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  NightMode: NightModeReducer,
  // Add other reducers if needed
});

export default rootReducer;
