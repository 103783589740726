import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./developmentprocess.css"

function DevelopmentProcess() {
  const { t } = useTranslation();
  return (
    <div style={{ width:"91.5vw", margin:"auto",paddingTop:"100px"}}>
        <div className='developmentprocess-section'>
            <h1 className="our-stories">{t("howwework")}</h1>
            <p className='about-us-description'>
             {t("aboutuspagedescription")}
            </p>
        </div>
      </div>
  )
}

export default DevelopmentProcess