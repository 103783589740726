import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {Row, Col, Image } from 'react-bootstrap'
import './service.css'

gsap.registerPlugin(ScrollTrigger);
function Service({icon,alt,serviceHeading,serviceDetails }) {
  const app = useRef();
  useLayoutEffect(() => {
      const ctx = gsap.context((self) => {
        const images = self.selector('.service-image');
        images.forEach((image) => {
          gsap.from(image, {
            opacity: 0,
            y: 50,
            duration: 1,
            stagger: 0.2,
            scrollTrigger: {
              trigger: image,
              start: "top 90%", // Adjust the start position for the trigger to fire the animation
              end: "bottom 90%", // Adjust the end position for the trigger to fire the animation
              scrub: 1, // Set to 1 for a smoother scrubbing effect
            },
          });
        });
        gsap.from(".service-name", {
          x: 70,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: ".service-name",
            start: "top 80%", // Adjust the start position for the trigger to fire the animation
            end: "bottom 90%", // Adjust the end position for the trigger to fire the animation
            scrub: 1, // Set to 1 for a smoother scrubbing effect
          },
        });
        gsap.from(".service-description", {
          x: 70,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: ".service-description",
            start: "top 80%", // Adjust the start position for the trigger to fire the animation
            end: "bottom 90%", // Adjust the end position for the trigger to fire the animation
            scrub: 1, // Set to 1 for a smoother scrubbing effect
          },
        });
      }, app); // <- Scope!
      return () => ctx.revert(); // <- Cleanup!
    }, []);
  return (
    <Row ref={app} className='px-4 mt-4 text-white'>
        <Col lg={4} >
            <div className='service-background'>
                <Image className='service-image' src={icon} width={150} alt={alt}></Image>
            </div>
        </Col>
        <Col lg={8}>
            <h2 className='service-name text-white m-3'>{serviceHeading}</h2>
            <p className='service-description text-white m-3'>{serviceDetails}</p>
        </Col>
    </Row>
  )
}

export default Service