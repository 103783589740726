import React, { useEffect, useState, useRef, useCallback } from 'react'
import { gsap } from 'gsap';
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Particles from '../particles';
import { useDispatch, useSelector } from 'react-redux';
import './mainsection.css'

function MainSection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nightMode = useSelector((state) => state.NightMode);
  const [particlesKey, setParticlesKey] = useState(1);
  useEffect(() => {
    // Whenever nightMode changes, update the key to trigger a re-render of Particles
    setParticlesKey((prevKey) => prevKey + 1);
  }, [nightMode]);
  return (
    <>
        <Particles  key={particlesKey}/>
        <main className={`main-section `}>
          <div className={nightMode?"night-main-background-section":'day-main-background-section'}></div>
            <Container className='main-container'>
                <Row>
                    <Col lg={12} sm={12}>
                        <h1 className={`main-heading ${nightMode?"night-text-color":"day-text-color"}`}>
                            {t("mainheading")}
                        </h1>
                        <h3 className={`main-subheading ${nightMode?"night-text-color":"day-text-color"}`}>
                          {t("mainsubheading")}
                        </h3>
                    </Col>
                </Row>
            </Container>
            <Container className='d-flex justify-content-center'>
              <Row>
                <Col>
                  {/* <button className='getquote'>Get a Quote</button> */}
                </Col>
              </Row>
            </Container>

        </main>
    </>
  )
}

export default MainSection