import React from 'react'
import Logo from "../logo/index"
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import "./footer.css"

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className='footer-section text-light bg-dark'>
          <Row>
            <Col md={4}>
              <Logo logotype="dark"/>
              <p>26 Curlew Way Inerkeithing, <br/> KY11 1FG United Kingdom</p>
            </Col>
            <Col className='d-flex justify-content-center'>
              <div>
                <h3>Let Us Know</h3>
                <div className='d-flex justify-content-center'>
                    <div className='d-block'>
                      <div className='mt-2'>
                        <a className='contactlinks' href='mailto:info@bringitgo.tech?subject='>info@bringitgo.tech</a>
                      </div>
                      <div className='mt-2'>
                        <a className='contactlinks' href='tel:+447413402742'>+44-7413402742</a>
                      </div>
                      <div className='mt-2'>
                        <a className='contactlinks' href='tel:+923028652470'>+92-3028652470</a>
                      </div>
                    </div>
                </div>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <div>
                <h3>Social Networks</h3>
                <div className="d-flex justify-content-center">
                  <a style={{textDecoration:"none", color:"white"}} href='https://www.facebook.com/profile.php?id=100089307017324' target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} size="2x" style={{ marginRight: '10px' }} />
                  </a>
                  <a style={{textDecoration:"none", color:"white"}} href='https://github.com/bringitgo' target='_blank'>
                    <FontAwesomeIcon icon={faGithub} size="2x" />

                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex justify-content-center mt-4 text-secondary'>
              <p>Copyright © {currentYear} Bringit Go. All Rights Reserved.</p>
            </Col>

          </Row>

    </div>
  )
}

export default Footer