import React from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({src}) => {
  const videoId = src;

  // Options for the YouTube player
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1, // set to 1 for autoplay
      controls: 0,
      mute: 1,
    },
  };

  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onReady={(event) => {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
      }}
    />
  );
};

export default VideoPlayer;
