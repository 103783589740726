import React  from "react";
import { Col, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import {  useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './project.css'
import constants from "../../../contants"
const {projectsCovers} = constants
const { datavisualiaztion, inkspire, inventory, pinggo, shareeftex, serverom, dressbylinda, exposurestudio, staytoenjoy}=projectsCovers;

function Projects() {
    const { t } = useTranslation();
  let nightMode = useSelector(state => state.NightMode);

  return (
    <div className={nightMode?"night-project-section":"day-project-section"}>
        <Row>
            <Col>
                <h2 className='project-heading'>{t("casestudies")}</h2>
            </Col>
        </Row>
        <Row>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
            >
                <SwiperSlide>
                    <a href="/project/inkspire">
                        <img style={{objectFit:'cover'}} src={inkspire.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/pinggo">
                        <img style={{objectFit:'cover'}} src={pinggo.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/data-visualization">
                        <img style={{objectFit:'cover'}} src={datavisualiaztion.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/dresses-by-linda-and-agnes">
                        <img style={{objectFit:'cover'}} src={dressbylinda.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/exposure-studio">
                        <img style={{objectFit:'cover'}} src={exposurestudio.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="./project/staytoenjoy">
                        <img style={{objectFit:'cover'}} src={staytoenjoy.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/shareef-tex">
                        <img style={{objectFit:'cover'}} src={shareeftex.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/inventory-store">
                        <img style={{objectFit:'cover'}} src={inventory.Image} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/project/serverom">
                        <img style={{objectFit:'cover'}} src={serverom.Image} />
                    </a>
                </SwiperSlide>
                <div className="slider-controler">
                    <div className="swiper-button-prev slider-arrow">
                        <ion-icon name="arrow-back-outline"></ion-icon>
                    </div>
                    <div className="swiper-button-next slider-arrow">
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </Swiper>
        </Row>
    </div>
  )
}

export default Projects