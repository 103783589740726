import React from 'react'
import DevelopmentProcess from '../../component/layout/developmentprocess'
import Technologies from '../../component/layout/technology'
import Contact from '../../component/layout/contact-form'
import {  useSelector } from 'react-redux';

function AboutUS() {
  let nightMode = useSelector(state => state.NightMode);

  return (
    <div className={nightMode?"night-theme":"day-theme"}>
        <DevelopmentProcess/>
        <Technologies/>
        <Contact/>
    </div>
  )
}

export default AboutUS