import React from 'react'
import Main from '../../component/layout/mainSection';
import Services from '../../component/layout/services';
import Projects from '../../component/layout/projects';
import Clients from '../../component/layout/client';
import Footer from '../../component/common/footer';
import Meeting from '../../component/layout/meeting';

function Home() {
  return (
    <>
        <Main/>
        <Services/>
        <Clients/>
        <Projects/>
        <Meeting/>
        <Footer/>
    </>
  )
}

export default Home