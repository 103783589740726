import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from "./dictionary/en.json"
import DE from "./dictionary/de.json"
import FR from "./dictionary/fr.json"

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
      fr: {
        translation: FR,
      },
      // Add more languages as needed
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation missing
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;