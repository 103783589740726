import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ErrorImg from "../../assests/404.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import {  useSelector } from 'react-redux';
import Logo from '../../component/common/logo/index';
import './nopagefound.css'

function NoPageFound() {
  let nightMode = useSelector(state => state.NightMode);
  return (
        <div className={nightMode?"bg-dark w-full":""} style={{maxWidth:"100vw",minHeight:"98vh"}}>
            <Row className="justify-content-center mt-2">
                <Col xs="auto">
                    <Logo/>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xs="auto">
                    <h1 className='error-code'><img src={ErrorImg} alt='404'/></h1>        
                </Col>
            </Row>
        </div>
    
  )
}

export default NoPageFound