import React, {useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Youtubevideo from "../youtubevideo/index.jsx"
import constants from "../../../contants"
import {  useSelector } from 'react-redux';
import './projectBody.css'
const {projects} = constants

function ProjectBody() {
    let nightMode = useSelector(state => state.NightMode);
    const { id } = useParams();
    let project;
    for (let index = 0; index < projects.length; index++) {
        if(projects[index].id===id){
            project=projects[index];
            break;
        }
    }
    if(!project){
        window.location.href = '/';
    }
    return (
        <div className={`project-body ${nightMode?"night-theme":"day-theme"}`}>
            <Row className='justify-content-center'>
                <Col mx='auto'>
                    <h1 className='project-name'>{project.Name}</h1>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center'>
                    
                    <img className='project-image' src={project.GIF?project.GIF:project.Image} />
                    {/* <Youtubevideo src={project.src}/> */}
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='project-challange'>
                    <h2 className='project-challange-heading'>Challange</h2>
                    <p className='detail'>{project.Challenge}</p>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='project-solution'>
                    <h2 className='project-description-heading'>Solution</h2>
                    <p className='detail'>{project.Description}</p>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='project-meta-details'>
                    <div className='d-flex align-items-center'>
                        <h2>Location: &nbsp;</h2>
                        <p className='detail'>{project.Location}</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <h2>Start Date: &nbsp;</h2>
                        <p className='detail'>{project.StartDate}</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <h2>End Date: &nbsp;</h2>
                        <p className='detail'>{project.EndDate}</p>
                    </div>
                </Col>

                <Col className='project-meta-details'>
                    <h2>TechStack</h2>
                        <div className='technologies-subcontainers'>
                            {project.TechStack.map((technology)=>{
                                return(
                                    <p className='detail'>
                                        <img className='technology-icon' width={50} src={technology} alt='technology icon'/>
                                    </p>
                                )
                            })}
                        </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProjectBody