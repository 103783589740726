import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { InlineWidget } from "react-calendly";
import ContactForm from "../contact-form/index"
import "./meeting.css"
function Meeting() {
  return (
    <Container className='mt-5 ' id="meeting">
        <Row>
            <Col>
                <ContactForm/>
            </Col>
            <Col className='calendly-meeting'>
                <InlineWidget url="https://calendly.com/bringitgo/30min" />
            </Col>
        </Row>
    </Container>
  )
}

export default Meeting