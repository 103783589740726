import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';
import Footer from '../../component/common/footer';
import "./price.css"
function Pricing() {
  const { t } = useTranslation();
  let nightMode = useSelector(state => state.NightMode);
  return (
    <div className={nightMode?"night-theme":"day-theme"}>
      <div className="pricing" id="pricing">
        <div style={{ width:"91.5vw", margin:"auto"}}>
          <Row>
            <Col>
                <h1 className="pricing-heading">{t("price")}</h1>
            </Col>
          </Row>
          <Row>
              <Col>
                <p className='pricing-description'>{t("priceDescription")}</p>
              </Col>
          </Row>
        </div>
        <div className='pricing-container pb-4'>
          <Row>
            <h2 className='project-heading mt-4'>{t("pricePlans")}</h2>
          </Row>
          <Row xl={4} lg={3} md={2} sm={2}>
            <Col>
              <div className={`price-container-background ${nightMode?"bg-dark":"price-bg"}`}>
                <h3 className='pricing-package-heading'>Maintainces</h3>
                <div className='package-feature'>
                  <p className='mx-3'>This is a package is to enhance and maintain the existing applications</p>
                  <ul>
                    <li>Small Projects</li>
                    <li>Custom Script</li>
                    <li>Website Fixes</li>
                    <li>Hosting</li>
                  </ul>
                </div>
                <h4 className='prices'>£100 - £1000</h4>
              </div>
            </Col>
            <Col>
              <div className={`price-container-background ${nightMode?"bg-dark":"price-bg"}`}>
                <h3 className='pricing-package-heading'>Designing</h3>
                <div className='package-feature'>
                  <p className='mx-3'>This is a monthly based designing subscription package</p>
                  <ul>
                    <li>Logos & Branding</li>
                    <li>Landing Pages</li>
                    <li>Email Templating</li>
                    <li>Social Media Graphics</li>
                    {/* <li>System Desigining</li>
                    <li>Web Application Designing</li>
                    <li>Mobile Application Designing</li>
                    <li>Social Media Poster Designing</li>
                    <li>Email Designing</li> */}
                  </ul>
                </div>
                <h4 className='prices'>£500 - £1500</h4>
              </div>
            </Col>
            <Col>
              <div className={`price-container-background ${nightMode?"bg-dark":"price-bg"}`}>
                <h3 className='pricing-package-heading'>Portfolio</h3>
                <div className='package-feature'>
                  <p className='mx-3'>This is a online presense building package.</p>
                  <ul>
                    <li>Five page website: Home, About, Sale Page, Services, Contact,</li>
                    <li>Responsive and user-friendly website</li>
                    <li>Essential functionalities for a strong online foundation</li>
                    <li>Basic SEO optimization</li>
                  </ul>
                </div>
                <h4 className='prices'>£1000 - £3000</h4>
              </div>
            </Col>
            <Col>
              <div className={`price-container-background ${nightMode?"bg-dark":"price-bg"}`}>
                <h3 className='pricing-package-heading'>SAAS</h3>
                <div className='package-feature'>
                  <p className='mx-3'>Thi is a complete web based service design solution</p>
                  <ul>
                    <li>System and interface designing</li>
                    <li>Service developement</li>
                    <li>Hosting service</li>
                    <li>One month maintances</li>
                  </ul>
                </div>
                <h4 className='prices'>£3000+</h4>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Pricing