import React, {useState} from 'react'
import { Container, NavDropdown, Navbar, Offcanvas, Nav, } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Logo from '../logo'
import PortfolioIcon from '../../../assests/portfolioIcon.png'
import AboutUsIcon from '../../../assests/aboutUsIcon.png'
import PriceIcon from '../../../assests/priceIcon.png'
import ContactIcon from '../../../assests/contactIcon.png'
import NightIcon from '../../../assests/DarkSwitch.png'
import DayIcon from '../../../assests/DaySwitch.png'
import { useDispatch, useSelector } from 'react-redux';
import { toggleDayNightMode } from '../../../redux/action';
// import CareerIcon from '../../../assests/careerIcon.png'
// import TestimonialIcon from '../../../assests/testimonial.png'
import './header.css'

function Header() {
  const dispatch = useDispatch();
  let nightMode = useSelector(state => state.NightMode);
  // nightMode=localStorage.getItem('mode');
  
  const { t,i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleToggleDayNightMode = () => {
    dispatch(toggleDayNightMode());
  };
  
  return (
    <header className={nightMode?"night-bg":"day-bg"}>
        {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="">
          <Container>
            <Navbar.Brand href="/">
                <Logo/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand} ${nightMode?"night-text-color":"day-text-color"}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand} ${nightMode?"night-text-color":"day-text-color"}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton className={nightMode?"night-bg night-text-color":"day-bg day-text-color"}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  What are you looking for
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className={nightMode?"night-bg":"day-bg"}>
                <Nav variant="underline" defaultActiveKey="/" className="justify-content-end flex-grow-1 pe-3 d-flex">
                  <NavDropdown
                    className={nightMode?"night-bg night-text-color":"day-bg day-text-color"}
                    title={t('services')}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/blockchain" className={nightMode?"night-text-color":"day-text-color"}>
                        {t('blockchain')}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/cloud" className={nightMode?"night-text-color":"day-text-color"}>
                        {t('cloudservices')}
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item href="/unity" className={nightMode?"night-text-color":"day-text-color"}>
                        Unity Development
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item href="/artificial-intelligence" className={nightMode?"night-text-color":"day-text-color"}>
                        Artificial Intelligence
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item href="/mechanical-design" className={nightMode?"night-text-color":"day-text-color"}>
                        Mechanical Designing
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item href="/mobile-development" className={nightMode?"night-text-color":"day-text-color"}>
                        {t('mobiledevelopment')}
                    </NavDropdown.Item> */}
                    <NavDropdown.Item href="/web" className={nightMode?"night-text-color":"day-text-color"}>
                        {t('web')}

                    </NavDropdown.Item>
                    <NavDropdown.Item href="/designing" className={nightMode?"night-text-color":"day-text-color"}>
                        {t('uxui')}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/portfolio" className={`d-flex me-1 align-items-center ${nightMode?"night-text-color":"day-text-color"}`}>
                        <img width={28} src={PortfolioIcon} alt='portfolio'/>&nbsp;
                        {t('portfolio')}
                  </Nav.Link>
                  <Nav.Link href="/about-us" className={`d-flex me-1 align-items-center ${nightMode?"night-text-color":"day-text-color"}`}>
                        <img className='' width={30} src={AboutUsIcon} alt='aboutus'/>&nbsp;
                        {t('aboutus')}
                  </Nav.Link>
                  <Nav.Link href="/pricing" className={`d-flex me-1 align-items-center ${nightMode?"night-text-color":"day-text-color"}`}>
                        <img className='' width={35} src={PriceIcon} alt='price'/>&nbsp;
                        {t('price')}
                  </Nav.Link>
                  <Nav.Link href="/#meeting" className={`d-flex me-1 align-items-center ${nightMode?"night-text-color":"day-text-color"}`}>
                        <img className='' width={30} src={ContactIcon} alt='contact'/>&nbsp;
                        {t('getintouch')}
                  </Nav.Link>
                  <Nav.Link className='d-flex'>
                        <button className={nightMode?"night-lang":"day-lang"} style={{border:"none", borderRight: '1px solid' }} onClick={() => changeLanguage('en')}>EN</button>
                        <button className={nightMode?"night-lang":"day-lang"} style={{border:"none", borderRight: '1px solid' }} onClick={() => changeLanguage('de')}>DE</button>
                        <button className={nightMode?"night-lang":"day-lang"} style={{border:"none"}} onClick={() => changeLanguage('fr')}>FR</button>       

                  </Nav.Link>
                  {!nightMode?
                    <>
                      <Nav.Link className='d-flex align-items-center night-mode' onClick={handleToggleDayNightMode}>
                        <img className='' width={40} src={NightIcon} alt='Day Mode'/>&nbsp;Light             
                      </Nav.Link>
                    </>
                    :
                    <>
                      <Nav.Link className='d-flex align-items-center day-mode night-text-color' onClick={handleToggleDayNightMode}>
                        Dark&nbsp;
                        <img className='' width={40} src={DayIcon} alt='Night Mode'/>               
                      </Nav.Link>
                    </>
                  }
                  {/* <Nav.Link href="/career" className='me-4'>
                        <img width={30} src={CareerIcon} alt='career'/>
                        Career
                  </Nav.Link> */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </header>
  )
}

export default Header